<template>
    <v-container>
        <v-row>
            <v-col>

                <v-card>
                    <v-card-title>
                        {{ $t('brands') }}

                        <v-spacer></v-spacer>

                        <v-btn color="info" @click="onAdd">
                            <v-icon dark left>mdi-plus</v-icon>
                            {{ $t('add_brand') }}
                        </v-btn>

                        <v-spacer></v-spacer>

                        <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                single-line
                                hide-details
                        ></v-text-field>
                    </v-card-title>

                    <v-data-table
                            :headers="headers"
                            :items="brands"
                            :search="search"
                            :items-per-page="15"
                            @click:row="onClickRow"
                    ></v-data-table>
                </v-card>

            </v-col>
        </v-row>

        <v-dialog v-model="dialog" persistent width="35%">
            <v-card>

                <v-form @submit.prevent="save">
                    <v-card-title>{{ $t('brand_details') }}</v-card-title>

                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                        :label="$t('name_en')"
                                        type="text"
                                        v-model="brandDetails.name_en"
                                />

                                <v-autocomplete
                                        :label="$t('favourite')"
                                        :items="getActive"
                                        :value="brandDetails.favourite"
                                        v-model="brandDetails.favourite"
                                >
                                </v-autocomplete>

                                <v-autocomplete
                                        :label="$t('active')"
                                        :items="getActive"
                                        :value="brandDetails.active"
                                        v-model="brandDetails.active"
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-btn color="error"
                               @click="dialog = false"
                               v-shortkey="['esc']" @shortkey="dialog = false"
                        >
                            <v-icon dark left>mdi-cancel</v-icon>
                            {{ $t('cancel') }}
                        </v-btn>

                        <v-spacer></v-spacer>

                        <v-btn type="submit" color="info">
                            <v-icon dark left>mdi-send</v-icon>
                            {{ $t('save') }}
                        </v-btn>
                    </v-card-actions>
                </v-form>

            </v-card>
        </v-dialog>

        <v-snackbar id="snackbar" :color=snackbarColor :top=snackbarTop v-model="snackbar">
            {{ snackbarMessage }}

            <v-btn text @click="snackbar=false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
    import {mapState, mapGetters} from 'vuex'
    import brandService from '@/services/BrandService.js'

    export default {
        name: "Brands",

        computed: {
            ...mapState(['isAdmin']),
            ...mapGetters(['getActive']),
        },

        created() {
            // Route guard
            if (!this.isAdmin) {
                this.$store.dispatch('logUserOut')
                this.$router.push('/login')
            }

            this.init()
        },

        data() {
            return {
                headers: [
                    {text: this.$t('id'), value: 'id'},
                    {text: this.$t('name_en'), value: 'name_en'},
                    {text: this.$t('favourite'), value: 'favourite'},
                    {text: this.$t('active'), value: 'active'},
                ],
                brands: [],
                search: '',
                dialog: false,

                brandDetails: {
                    id: '',
                    name_en: '',
                    favourite: '',
                    active: '',
                },

                snackbar: false,
                snackbarTop: true,
                snackbarColor: '',
                snackbarMessage: '',
            }
        },

        methods: {
            init() {
                brandService.all()
                    .then(response => {
                        this.brands = response.data
                    })
                    .catch(() => {
                        this.snackbar = true
                        this.snackbarColor = "error"
                        this.snackbarMessage = this.$t('invalid_data')
                    })
            },

            onClickRow(rowData) {
                this.dialog = true

                this.brandDetails = rowData
            },

            onAdd() {
                this.dialog = true

                this.brandDetails.id = ''
                this.brandDetails.name_en = ''
                this.brandDetails.favourite = ''
                this.brandDetails.active = ''
            },

            save() {
                this.dialog = false

                brandService.save(this.brandDetails)
                    .then(() => {
                        this.snackbar = true
                        this.snackbarColor = "success"
                        this.snackbarMessage = this.$t('data_saved')

                        this.init()
                    })
                    .catch(() => {
                        this.snackbar = true
                        this.snackbarColor = "error"
                        this.snackbarMessage = this.$t('invalid_data')
                    })
            },
        },
    }
</script>

